import React, { useState } from 'react';
import ReactDom from 'react-dom';
import './css/LoginPage.css';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import $ from 'jquery';
import InvoicePage from './InvoicePage';
import logo from './img/qs-logo2.png';

function LoginPage() {
  $(document).ready(function () {
    $("#validationCustomUsername").focus();
  });

  function FormLogin() {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

      const username = document.querySelector("[name='username'").value;
      const password = document.querySelector("[name='password'").value;
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (username !== "srki" && password !== "nekipass") {
        event.preventDefault();
        event.stopPropagation();
        $(".invalid-feedback").show();
      } else if (username !== "srki") {
        event.preventDefault();
        event.stopPropagation();
        $(".usernameDiv .invalid-feedback").show();
      } else if (password !== "nekipass") {
        event.preventDefault();
        event.stopPropagation();
        $(".passwordDiv .invalid-feedback").show();
      }
      if (username === "srki" && password === "nekipass") {
        event.preventDefault();
        event.stopPropagation();
        $(".invalid-feedback").hide();
        $(".loginRow").fadeOut("400");
        setTimeout(function () {
          ReactDom.render(<InvoicePage />, document.getElementById('root'));
        }, 500);
      }
      if (username === "milos" && password === "quadrixs@") {
        event.preventDefault();
        event.stopPropagation();
        $(".invalid-feedback").hide();
        $(".loginRow").fadeOut("400");
        setTimeout(function () {
          ReactDom.render(<InvoicePage />, document.getElementById('root'));
        }, 500);
      }
      setValidated(true);
    };

    return (
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3 usernameDiv" controlId="validationCustomUsername">
          {/* <Form.Label>Username</Form.Label> */}
          <InputGroup hasValidation>
            <Form.Control type="text" name="username" placeholder="Enter your username" aria-describedby="inputGroupPrepend" required />
            <Form.Control.Feedback type="invalid">
              Wrong username!
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3 passwordDiv" controlId="formBasicPassword">
          {/* <Form.Label>Password</Form.Label> */}
          <InputGroup hasValidation>
            <Form.Control type="password" name="password" placeholder="Password" required />
            <Form.Control.Feedback type="invalid">
              Wrong password!
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Button className="w-100" variant="primary" type="submit">
          Login
        </Button>
      </Form>
    );
  }

  return (
    <Container className="vh-100">
      <Row className="text-center pt-5">
        <Col>
          <img className="loginLogo" src={logo} alt="logo" />
        </Col>
      </Row>
      <Row className="md-center align-items-center loginRow pt-5 mt-5">
        <Col md={4} className="mx-auto">
          <h1 className="text-center mb-5"><strong>MY INVOICES</strong></h1>
          <h4 className="text-center mb-2">Please login</h4>
          <FormLogin />
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
