import React, { useRef, useEffect } from 'react';
import './css/InvoicePage.css';
import { Container, Row, Col, Form } from 'react-bootstrap';
import $ from 'jquery';
import logo from './img/qs-logo2.png';
import "hammerjs";
import "@progress/kendo-theme-material/dist/all.css";
import { PDFExport } from '@progress/kendo-react-pdf';
import request from 'superagent';


function InvoicePage() {

    $.ajax({
        url: 'https://api.kursna-lista.info/5a460f2c0d0403301893afd68912ccad/konvertor/eur/rsd/1',
        dataType: 'jsonp',
        success: function (json) {
            var eurValueAll = json.result.value;
            //var eurValue = eurValueAll.slice(0, -2);
            $(".eurValue").val(eurValueAll);
        }
    });

    request
        .get('http://localhost:8080/api/invoiceNum')
        .set('Content-Type', 'application/json')
        .end(function (err, res) {
            $(".invoiceNumField").val(Number(res.body.data[0].last_invoce_num) + Number(1) + "/21");
        });

    useEffect(() => {
        request
            .get('http://localhost:8080/api/quadrixsData/6156b917a894192ffc02cc96')
            .set('Content-Type', 'application/json')
            .end(function (err, res) {

                var qsName = res.body.data.qs_name;
                var qsAddress = res.body.data.qs_address;
                var qsCity = res.body.data.qs_cityzip;
                var qsState = res.body.data.qs_state;
                var qsEmail = res.body.data.qs_email;
                var qsPhone = res.body.data.qs_phone;
                var qsBank = res.body.data.qs_bank;
                var qsPib = res.body.data.qs_pib;

                $(".qsCol #formBasicText10").val(qsName).css("background", "#fff");
                $(".qsCol #formBasicText11").val(qsAddress).css("background", "#fff");
                $(".qsCol #formBasicText12").val(qsCity).css("background", "#fff");
                $(".qsCol #formBasicText13").val(qsState).css("background", "#fff");
                $(".qsCol #formBasicText14").val(qsEmail).css("background", "#fff");
                $(".qsCol #formBasicText15").val(qsPhone).css("background", "#fff");
                $(".qsCol #formBasicText16").val(qsBank).css("background", "#fff");
                $(".qsCol #formBasicText18").val(qsPib).css("background", "#fff");
            });
    }, []);

    useEffect(() => {
        request
            .get('http://localhost:8080/api/clientsData')
            .set('Content-Type', 'application/json')
            .end(function (err, res) {
                const obj = res.body.data;
                let clients = [];
                for (let i = 0; i < obj.length; i++) {
                    clients.push('<option id=' + obj[i]._id + '>' + obj[i].client_name + '</option>');
                }
                $("#selOption").append(clients);

                $("body").on("change", "#selOption", function () {

                    var selectedOif = $("#selOption").children("option:selected").val();

                    if (selectedOif === "New Client") {
                        $(".btnSaveClient").fadeIn();
                        $("#formBasicText2").val("").css("background", "#e4e4e4");
                        $("#formBasicText3").val("").css("background", "#e4e4e4");
                        $("#formBasicText4").val("").css("background", "#e4e4e4");
                        $("#formBasicText5").val("").css("background", "#e4e4e4");
                        $("#formBasicText6").val("").css("background", "#e4e4e4");
                        $("#formBasicText8").val("").css("background", "#e4e4e4");
                        $("#formBasicText9").val("").css("background", "#e4e4e4");

                        $("#formBasicText1").hide();
                        $("#formBasicText1a").show().append('<i class="bi bi-x-circle closeFieldInput"><span class="tooltipClientName">back to select client from data base</span></i>');
                        $("#formBasicText1a input").focus();
                        $(".clientUpdateBtn").addClass("dp-noneImp");
                        $(".clientDeleteBtn").addClass("dp-noneImp");

                        $("body").on("click", ".btnSaveClient", function () {
                            var newName = $("#formBasicText1a input").val();
                            var newAddress = $("#formBasicText2").val();
                            var newCity = $("#formBasicText3").val();
                            var newState = $("#formBasicText4").val();
                            var newEmail = $("#formBasicText5").val();
                            var newPhone = $("#formBasicText6").val();
                            var newBank = $("#formBasicText8").val();
                            var newTax = $("#formBasicText9").val();

                            request
                                .post('http://localhost:8080/api/clientsData')
                                .set('Content-Type', 'application/json')
                                .send({ client_name: newName, client_address: newAddress, client_cityzip: newCity, client_state: newState, client_email: newEmail, clinet_phone: newPhone, client_bank: newBank, client_taxid: newTax })
                                .end(function (err, res) {

                                    if (res.status === 404) {
                                        $(".btnSaveClient").fadeOut();
                                        setTimeout(function () {
                                            $(".newClientSpinner").fadeIn();
                                        }, 500);
                                        setTimeout(function () {
                                            $(".newClientSpinner").fadeOut();
                                        }, 1000);
                                        setTimeout(function () {
                                            $(".infoNotOk").fadeIn();
                                        }, 1500);
                                        setTimeout(function () {
                                            $(".infoNotOk").fadeOut();
                                        }, 5000);
                                    }

                                    if (res.status === 200 && res.body.message === "New contact created!") {
                                        $(".btnSaveClient").fadeOut();
                                        setTimeout(function () {
                                            $(".newClientSpinner").fadeIn();
                                        }, 500);
                                        setTimeout(function () {
                                            $(".newClientSpinner").fadeOut();
                                        }, 1000);
                                        setTimeout(function () {
                                            $(".infoOk").fadeIn();
                                        }, 1500);
                                        setTimeout(function () {
                                            $(".infoOk").fadeOut();
                                        }, 5000);
                                    }
                                    $(".clientUpdateBtn").removeClass("dp-noneImp").hide();
                                    $(".clientDeleteBtn").removeClass("dp-noneImp").hide();
                                });
                        });

                        $("body").on("click", ".closeFieldInput", function () {
                            $(".btnSaveClient").fadeOut();
                            $(".formBasicText1a input").val("");
                            $(".formBasicText1a").hide();
                            $(".formBasicText1").show();
                            $("#selOption").val($("#selOption option:first").val());
                            $("#formBasicText2").val("").css("background", "#e4e4e4");
                            $("#formBasicText3").val("").css("background", "#e4e4e4");
                            $("#formBasicText4").val("").css("background", "#e4e4e4");
                            $("#formBasicText5").val("").css("background", "#e4e4e4");
                            $("#formBasicText6").val("").css("background", "#e4e4e4");
                            $("#formBasicText8").val("").css("background", "#e4e4e4");
                            $("#formBasicText9").val("").css("background", "#e4e4e4");
                            $(".clientUpdateBtn").removeClass("dp-noneImp").addClass("dp-none").hide();
                            $(".clientDeleteBtn").removeClass("dp-noneImp").addClass("dp-none").hide();
                        });

                        $(".clientForm input").keydown(function () {
                            $(this).css("background", "#fff");
                        });
                    } else {
                        var selectedD = $("#selOption").children("option:selected").attr("id");

                        request
                            .get('http://localhost:8080/api/clientsData/' + selectedD)
                            .set('Content-Type', 'application/json')
                            .end(function (err, res) {

                                var clientAddress = res.body.data.client_address;
                                var clientCity = res.body.data.client_cityzip;
                                var clientState = res.body.data.client_state;
                                var clientEmail = res.body.data.client_email;
                                var clientPhone = res.body.data.clinet_phone;
                                var clientBank = res.body.data.client_bank;
                                var clientTax = res.body.data.client_taxid;

                                $("#formBasicText2").val(clientAddress).css("background", "#fff");
                                $("#formBasicText3").val(clientCity).css("background", "#fff");
                                $("#formBasicText4").val(clientState).css("background", "#fff");
                                $("#formBasicText5").val(clientEmail).css("background", "#fff");
                                $("#formBasicText6").val(clientPhone).css("background", "#fff");
                                $("#formBasicText8").val(clientBank).css("background", "#fff");
                                $("#formBasicText9").val(clientTax).css("background", "#fff");
                            });
                    }
                });
            });
    }, []);


    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '.' + mm + '.' + yyyy;


    const pdfExportComponent = useRef(null);

    const handleExportWithComponent = event => {
        pdfExportComponent.current.save();
    };

    $(document).ready(function () {

        $(".todayDate").val(today);

        $(".invoiceForm input, .sumTxt").each(function () {
            if (!$(this).val()) {
                $(this).addClass("emptyField");
            }
        });

        $(".invoiceForm input, .sumTxt").keydown(function () {
            if (!$(this).val()) {
                $(this).removeClass("emptyField");
            }
        });

        $("body").on("change", "#qsBank", function () {
            var selectedF = $("#qsBank").children("option:selected").val();


            if (selectedF === "EUR") {

                request
                    .get('http://localhost:8080/api/quadrixsData/6156b917a894192ffc02cc96')
                    .set('Content-Type', 'application/json')
                    .end(function (err, res) {

                        var qsName = res.body.data.qs_name;
                        var qsAddress = res.body.data.qs_address;
                        var qsCity = res.body.data.qs_cityzip;
                        var qsState = res.body.data.qs_state;
                        var qsEmail = res.body.data.qs_email;
                        var qsPhone = res.body.data.qs_phone;
                        var qsBank = res.body.data.qs_bank;
                        var qsPib = res.body.data.qs_pib;

                        $(".qsCol #formBasicText10").val(qsName).css("background", "#fff");
                        $(".qsCol #formBasicText11").val(qsAddress).css("background", "#fff");
                        $(".qsCol #formBasicText12").val(qsCity).css("background", "#fff");
                        $(".qsCol #formBasicText13").val(qsState).css("background", "#fff");
                        $(".qsCol #formBasicText14").val(qsEmail).css("background", "#fff");
                        $(".qsCol #formBasicText15").val(qsPhone).css("background", "#fff");
                        $(".qsCol #formBasicText16").val(qsBank).css("background", "#fff");
                        $(".qsCol #formBasicText18").val(qsPib).css("background", "#fff");
                    });

                //$(".qsBankInput input").val("265-1218884-89");
                $("#selTax").children("option").prop('selected', false);
                $("#selTax").children("option:nth-child(1)").prop('selected', true);
                //$(".pibNum").val("111181209");
                $("#table").show();
                $("#table2").hide();
                $(".inTxt").show();
                $(".srTxt").hide();
                $(".invoiceTitle").text("Invoice No.");
                $(".dolVal").hide();
                $(".euVal").show();
                $(".radioDiv").removeClass("srLng").addClass("inLng");
                $(".euRow").show();
                $(".usdRow").hide();
                $(".signaturesRow.rsdRow").removeClass("dp-flex");
                $(".rsdRow").hide();
                // $(".inAddress").show();
                // $(".srAddress").addClass("dp-none");
                // $(".inCityZip").show();
                // $(".srCityZip").addClass("dp-none");
                // $(".inState").show();
                // $(".srState").addClass("dp-none");
                $(".projTitleCol").removeClass("col-md-8");
                $(".projTitleCol").addClass("col-md-12");
                $(".srDatProm").hide();
            }
            if (selectedF === "USD") {

                request
                    .get('http://localhost:8080/api/quadrixsData/6156bb54a894192ffc02cc9b')
                    .set('Content-Type', 'application/json')
                    .end(function (err, res) {

                        var qsName = res.body.data.qs_name;
                        var qsAddress = res.body.data.qs_address;
                        var qsCity = res.body.data.qs_cityzip;
                        var qsState = res.body.data.qs_state;
                        var qsEmail = res.body.data.qs_email;
                        var qsPhone = res.body.data.qs_phone;
                        var qsBank = res.body.data.qs_bank;
                        var qsPib = res.body.data.qs_pib;

                        $(".qsCol #formBasicText10").val(qsName).css("background", "#fff");
                        $(".qsCol #formBasicText11").val(qsAddress).css("background", "#fff");
                        $(".qsCol #formBasicText12").val(qsCity).css("background", "#fff");
                        $(".qsCol #formBasicText13").val(qsState).css("background", "#fff");
                        $(".qsCol #formBasicText14").val(qsEmail).css("background", "#fff");
                        $(".qsCol #formBasicText15").val(qsPhone).css("background", "#fff");
                        $(".qsCol #formBasicText16").val(qsBank).css("background", "#fff");
                        $(".qsCol #formBasicText18").val(qsPib).css("background", "#fff");
                    });

                //$(".qsBankInput input").val("265-1218883-92");
                $("#selTax").children("option").prop('selected', false);
                $("#selTax").children("option:nth-child(2)").prop('selected', true);
                //$(".pibNum").val("111181209");
                $("#table").show();
                $("#table2").hide();
                $(".inTxt").show();
                $(".srTxt").hide();
                $(".invoiceTitle").text("Invoice No.");
                $(".dolVal").show();
                $(".euVal").hide();
                $(".radioDiv").removeClass("srLng").addClass("inLng");
                $(".euRow").hide();
                $(".usdRow").show();
                $(".signaturesRow.rsdRow").removeClass("dp-flex");
                $(".rsdRow").hide();
                // $(".inAddress").show();
                // $(".srAddress").addClass("dp-none");
                // $(".inCityZip").show();
                // $(".srCityZip").addClass("dp-none");
                // $(".inState").show();
                // $(".srState").addClass("dp-none");
                $(".projTitleCol").removeClass("col-md-8");
                $(".projTitleCol").addClass("col-md-12");
                $(".srDatProm").hide();
            }
            if (selectedF === "RSD") {

                request
                    .get('http://localhost:8080/api/quadrixsData/6156bcbda894192ffc02cca6')
                    .set('Content-Type', 'application/json')
                    .end(function (err, res) {

                        var qsName = res.body.data.qs_name;
                        var qsAddress = res.body.data.qs_address;
                        var qsCity = res.body.data.qs_cityzip;
                        var qsState = res.body.data.qs_state;
                        var qsEmail = res.body.data.qs_email;
                        var qsPhone = res.body.data.qs_phone;
                        var qsBank = res.body.data.qs_bank;
                        var qsPib = res.body.data.qs_pib;

                        $(".qsCol #formBasicText10").val(qsName).css("background", "#fff");
                        $(".qsCol #formBasicText11").val(qsAddress).css("background", "#fff");
                        $(".qsCol #formBasicText12").val(qsCity).css("background", "#fff");
                        $(".qsCol #formBasicText13").val(qsState).css("background", "#fff");
                        $(".qsCol #formBasicText14").val(qsEmail).css("background", "#fff");
                        $(".qsCol #formBasicText15").val(qsPhone).css("background", "#fff");
                        $(".qsCol #formBasicText16").val(qsBank).css("background", "#fff");
                        $(".qsCol #formBasicText18").val(qsPib).css("background", "#fff");
                    });

                //$(".qsBankInput input").val("265-4010310004561-43");
                $("#selTax").children("option").prop('selected', false);
                $("#selTax").children("option:nth-child(3)").prop('selected', true);
                //$(".pibNum").val("111181209");
                $("#table").hide();
                $("#table2").show();
                $(".inTxt").hide();
                $(".srTxt").show().css("display", "block");
                $(".invoiceTitle").text("Faktura Br.");
                $(".radioDiv").removeClass("inLng").addClass("srLng");
                $(".euRow").hide();
                $(".usdRow").hide();
                $(".signaturesRow.rsdRow").addClass("dp-flex");
                $(".rsdRow").show();
                // $(".inAddress").hide();
                // $(".srAddress").removeClass("dp-none");
                // $(".inCityZip").hide();
                // $(".srCityZip").removeClass("dp-none");
                // $(".inState").hide();
                // $(".srState").removeClass("dp-none");
                $(".projTitleCol").removeClass("col-md-12");
                $(".projTitleCol").addClass("col-md-8");
                $(".srDatProm").show();
            }
        });

        $("body").on("change", "#selTax", function () {
            var selectedT = $("#selTax").children("option:selected").val();

            if (selectedT === "VAT") {

                request
                    .get('http://localhost:8080/api/quadrixsData/6156b917a894192ffc02cc96')
                    .set('Content-Type', 'application/json')
                    .end(function (err, res) {

                        var qsName = res.body.data.qs_name;
                        var qsAddress = res.body.data.qs_address;
                        var qsCity = res.body.data.qs_cityzip;
                        var qsState = res.body.data.qs_state;
                        var qsEmail = res.body.data.qs_email;
                        var qsPhone = res.body.data.qs_phone;
                        var qsBank = res.body.data.qs_bank;
                        var qsPib = res.body.data.qs_pib;

                        $(".qsCol #formBasicText10").val(qsName).css("background", "#fff");
                        $(".qsCol #formBasicText11").val(qsAddress).css("background", "#fff");
                        $(".qsCol #formBasicText12").val(qsCity).css("background", "#fff");
                        $(".qsCol #formBasicText13").val(qsState).css("background", "#fff");
                        $(".qsCol #formBasicText14").val(qsEmail).css("background", "#fff");
                        $(".qsCol #formBasicText15").val(qsPhone).css("background", "#fff");
                        $(".qsCol #formBasicText16").val(qsBank).css("background", "#fff");
                        $(".qsCol #formBasicText18").val(qsPib).css("background", "#fff");
                    });

                //$(".qsBankInput input").val("265-1218884-89");
                $("#qsBank").children("option").prop('selected', false);
                $("#qsBank").children("option:nth-child(1)").prop('selected', true);
                //$(".pibNum").val("111181209");
                $("#table").show();
                $("#table2").hide();
                $(".inTxt").show();
                $(".srTxt").hide();
                $(".invoiceTitle").text("Invoice No.");
                $(".dolVal").hide();
                $(".euVal").show();
                $(".radioDiv").removeClass("srLng").addClass("inLng");
                $(".euRow").show();
                $(".usdRow").hide();
                $(".rsdRow").hide();
                $(".signaturesRow.rsdRow").removeClass("dp-flex");
                $(".rsdRow").hide();
                // $(".inAddress").show();
                // $(".srAddress").addClass("dp-none");
                // $(".inCityZip").show();
                // $(".srCityZip").addClass("dp-none");
                // $(".inState").show();
                // $(".srState").addClass("dp-none");
                $(".projTitleCol").removeClass("col-md-8");
                $(".projTitleCol").addClass("col-md-12");
                $(".srDatProm").hide();

            }
            if (selectedT === "TIN") {

                request
                    .get('http://localhost:8080/api/quadrixsData/6156bb54a894192ffc02cc9b')
                    .set('Content-Type', 'application/json')
                    .end(function (err, res) {

                        var qsName = res.body.data.qs_name;
                        var qsAddress = res.body.data.qs_address;
                        var qsCity = res.body.data.qs_cityzip;
                        var qsState = res.body.data.qs_state;
                        var qsEmail = res.body.data.qs_email;
                        var qsPhone = res.body.data.qs_phone;
                        var qsBank = res.body.data.qs_bank;
                        var qsPib = res.body.data.qs_pib;

                        $(".qsCol #formBasicText10").val(qsName).css("background", "#fff");
                        $(".qsCol #formBasicText11").val(qsAddress).css("background", "#fff");
                        $(".qsCol #formBasicText12").val(qsCity).css("background", "#fff");
                        $(".qsCol #formBasicText13").val(qsState).css("background", "#fff");
                        $(".qsCol #formBasicText14").val(qsEmail).css("background", "#fff");
                        $(".qsCol #formBasicText15").val(qsPhone).css("background", "#fff");
                        $(".qsCol #formBasicText16").val(qsBank).css("background", "#fff");
                        $(".qsCol #formBasicText18").val(qsPib).css("background", "#fff");
                    });

                //$(".qsBankInput input").val("265-1218883-92");
                $("#qsBank").children("option").prop('selected', false);
                $("#qsBank").children("option:nth-child(2)").prop('selected', true);
                //$(".pibNum").val("111181209");
                $("#table").show();
                $("#table2").hide();
                $(".inTxt").show();
                $(".srTxt").hide();
                $(".invoiceTitle").text("Invoice No.");
                $(".dolVal").show();
                $(".euVal").hide();
                $(".radioDiv").removeClass("srLng").addClass("inLng");
                $(".euRow").hide();
                $(".usdRow").show();
                $(".rsdRow").hide();
                $(".signaturesRow.rsdRow").removeClass("dp-flex");
                $(".rsdRow").hide();
                // $(".inAddress").show();
                // $(".srAddress").addClass("dp-none");
                // $(".inCityZip").show();
                // $(".srCityZip").addClass("dp-none");
                // $(".inState").show();
                // $(".srState").addClass("dp-none");
                $(".projTitleCol").removeClass("col-md-8");
                $(".projTitleCol").addClass("col-md-12");
                $(".srDatProm").hide();
            }
            if (selectedT === "PIB") {

                request
                    .get('http://localhost:8080/api/quadrixsData/6156bcbda894192ffc02cca6')
                    .set('Content-Type', 'application/json')
                    .end(function (err, res) {

                        var qsName = res.body.data.qs_name;
                        var qsAddress = res.body.data.qs_address;
                        var qsCity = res.body.data.qs_cityzip;
                        var qsState = res.body.data.qs_state;
                        var qsEmail = res.body.data.qs_email;
                        var qsPhone = res.body.data.qs_phone;
                        var qsBank = res.body.data.qs_bank;
                        var qsPib = res.body.data.qs_pib;

                        $(".qsCol #formBasicText10").val(qsName).css("background", "#fff");
                        $(".qsCol #formBasicText11").val(qsAddress).css("background", "#fff");
                        $(".qsCol #formBasicText12").val(qsCity).css("background", "#fff");
                        $(".qsCol #formBasicText13").val(qsState).css("background", "#fff");
                        $(".qsCol #formBasicText14").val(qsEmail).css("background", "#fff");
                        $(".qsCol #formBasicText15").val(qsPhone).css("background", "#fff");
                        $(".qsCol #formBasicText16").val(qsBank).css("background", "#fff");
                        $(".qsCol #formBasicText18").val(qsPib).css("background", "#fff");
                    });

                //$(".qsBankInput input").val("265-4010310004561-43");
                $("#qsBank").children("option").prop('selected', false);
                $("#qsBank").children("option:nth-child(3)").prop('selected', true);
                $("#table").hide();
                $("#table2").show();
                $(".inTxt").hide();
                $(".srTxt").show().css("display", "block");
                $(".invoiceTitle").text("Faktura Br.");
                $(".radioDiv").removeClass("inLng").addClass("srLng");
                $(".euRow").hide();
                $(".usdRow").hide();
                $(".signaturesRow.rsdRow").addClass("dp-flex");
                $(".rsdRow").show();
                $(".signaturesRow.rsdRow").addClass("dp-flex");
                $(".rsdRow").show();
                // $(".inAddress").hide();
                // $(".srAddress").removeClass("dp-none");
                // $(".inCityZip").hide();
                // $(".srCityZip").removeClass("dp-none");
                // $(".inState").hide();
                // $(".srState").removeClass("dp-none");
                $(".projTitleCol").removeClass("col-md-12");
                $(".projTitleCol").addClass("col-md-8");
                $(".srDatProm").show();
            }
        });

        $(".customField .addInputField").on("click", function () {
            $(this).hide();
            $(".customField .newInputField").fadeIn("400").addClass("activeField");
            $(".customField .newInputField input").focus();
            $(".customField2").fadeIn("400").addClass("activeField");
            $(".inFild1").addClass("hasVal");
        });

        $(".customField .closeField").on("click", function () {
            $(".inFild1").removeClass("hasVal");
            $(".inFild2").removeClass("hasVal");
            $(".inFild3").removeClass("hasVal");
            $(".inFild4").removeClass("hasVal");
            $(".customField .newInputField").hide().removeClass("activeField");
            $(".customField .addInputField").fadeIn("400");
            $(".customField2").hide().removeClass("activeField");
            if ($(".customField3").hasClass("activeField") || $(".customField4").hasClass("activeField")) {
                $(".customField2 .newInputField").hide().removeClass("activeField");
                $(".customField2 .addInputField").fadeIn("400");
                $(".customField3").hide().removeClass("activeField");
                $(".customField3 .newInputField").hide().removeClass("activeField");
                $(".customField3 .addInputField").fadeIn("400");
                $(".customField4").hide().removeClass("activeField");
                $(".customField4 .newInputField").hide().removeClass("activeField");
                $(".customField4 .addInputField").fadeIn("400");
                $(".customField2 input").val("");
                $(".customField3 input").val("");
                $(".customField4 input").val("");
            }
            $(".customField input").val("");
        });

        $(".customField2 .addInputField").on("click", function () {
            $(this).hide();
            $(".customField2 .newInputField").fadeIn("400").addClass("activeField");
            $(".customField2 .newInputField input").focus();
            $(".customField3").fadeIn("400").addClass("activeField");
            $(".inFild2").addClass("hasVal");
        });

        $(".customField2 .closeField").on("click", function () {
            $(".inFild2").removeClass("hasVal");
            $(".inFild3").removeClass("hasVal");
            $(".inFild4").removeClass("hasVal");
            $(".customField2 .newInputField").hide().removeClass("activeField");
            $(".customField2 .addInputField").fadeIn("400");
            $(".customField3").hide().removeClass("activeField");
            if ($(".customField4").hasClass("activeField")) {
                $(".customField3 .newInputField").hide().removeClass("activeField");
                $(".customField3 .addInputField").fadeIn("400");
                $(".customField4").hide().removeClass("activeField");
                $(".customField4 .newInputField").hide().removeClass("activeField");
                $(".customField4 .addInputField").fadeIn("400");
                $(".customField3 input").val("");
                $(".customField4 input").val("");
            }
            $(".customField2 input").val("");
        });

        $(".customField3 .addInputField").on("click", function () {
            $(this).hide();
            $(".customField3 .newInputField").fadeIn("400").addClass("activeField");
            $(".customField3 .newInputField input").focus();
            $(".customField4").fadeIn("400").addClass("activeField");
            $(".inFild3").addClass("hasVal");
        });

        $(".customField3 .closeField").on("click", function () {
            $(".inFild3").removeClass("hasVal");
            $(".inFild4").removeClass("hasVal");
            $(".customField3 .newInputField").hide().removeClass("activeField");
            $(".customField3 .addInputField").fadeIn("400");
            $(".customField4").hide().removeClass("activeField");
            $(".customField4 .newInputField").hide().removeClass("activeField");
            $(".customField4 .addInputField").fadeIn("400");
            $(".customField4 input").val("");
            $(".customField3 input").val("");
        });
        $(".customField4 .addInputField").on("click", function () {
            $(this).hide();
            $(".customField4 .newInputField").fadeIn("400").addClass("activeField");
            $(".customField4 .newInputField input").focus();
            $(".inFild4").addClass("hasVal");
        });

        $(".customField4 .closeField").on("click", function () {
            $(".inFild4").removeClass("hasVal");
            $(".customField4 .newInputField").hide().removeClass("activeField");
            $(".customField4 .addInputField").fadeIn("400");
            $(".customField4 input").val("");
        });

        // ========================================
        const $tableID = $('#table');
        const $tableID2 = $('#table2');
        const $BTN = $('#export-btn');
        const $EXPORT = $('#export');
        const newTr = `
            <tr class="hide">
            <td class="pt-3-half pos" contentEditable="true"></td>
            <td class="pt-3-half inServiceName" contentEditable="true"></td>
            <td class="pt-3-half inAddWork" contentEditable="true"></td>
            <td class="pt-3-half inPriceWork" contentEditable="true"></td>
            <td class="pt-3-half inTotalPrice" contentEditable="true"></td>
            <td class="iconCol">
                <span class="table-remove">
                    <!-- <button type="button" class="btn btn-danger btn-rounded btn-sm my-0 waves-effect waves-light"> -->
                        <i class="bi bi-x-circle"></i>
                    <!-- </button> -->
                </span>
            </td>
            </tr>
            `;
        const newTr2 = `
            <tr class="hide">
            <td class="pt-3-half pos" contentEditable="true"></td>
            <td class="pt-3-half srServiceName" contentEditable="true"></td>
            <td class="pt-3-half srAddWork" contentEditable="true"></td>
            <td class="pt-3-half srPriceWork" contentEditable="true"></td>
            <td class="pt-3-half srTotalPrice" contentEditable="true"></td>
            <td class="pt-3-half srTotalPricePdv" contentEditable="true"></td>
            <td class="pt-3-half srPdv" contentEditable="true"></td>
            <td class="pt-3-half srTotalPdv" contentEditable="true"></td>
            <td class="iconCol">
                <span class="table-remove">
                    <!-- <button type="button" class="btn btn-danger btn-rounded btn-sm my-0 waves-effect waves-light"> -->
                        <i class="bi bi-x-circle"></i>
                    <!-- </button> -->
                </span>
            </td>
            </tr>
            `;
        $('#table .table-add').on('click', 'i', (event) => {
            const getPos = $("#table .pos").last().text();
            let newPos = Number(getPos) + 1;

            $('#table tbody').append(newTr);
            $("#table .pos").last().text(newPos);
            var isTdEmpty = $(".inPriceWork").last().text();
            if (isTdEmpty === '') {
                $(".table-add").hide();
            } else {
                $(".table-add").show();
            }
            $(".inServiceName").last().focus();
            event.stopImmediatePropagation();
        });
        $('#table2 .table-add2').on('click', 'i', (event) => {
            const getPos = $("#table2 .pos").last().text();
            let newPos = Number(getPos) + 1;

            $('#table2 tbody').append(newTr2);
            $("#table2 .pos").last().text(newPos);

            var isTdEmpty = $(".srPriceWork").last().text();
            if (isTdEmpty === '') {
                $(".table-add2").hide();
            } else {
                $(".table-add2").show();
            }
            var pos = $("#table2 .pos").last().width();
            var srServiceName = $(".srServiceName").last().width();
            var srPriceWork = $(".srPriceWork").last().width();
            var srTotalPrice = $(".srTotalPrice").last().width();
            var totalTd = Number(pos) + Number(srServiceName);

            var getElemWidth = $(".srAddWork").last().width();
            $(".addWorkTotal").css({ "margin-left": totalTd + 4, "width": Number(getElemWidth) + 3 });

            var totalTd2 = Number(srPriceWork) + Number(srTotalPrice);
            var getOsElemWidth = $(".srTotalPricePdv").width();
            $(".addOsTotal").css({ "margin-left": Number(totalTd2) + 3, "width": Number(getOsElemWidth) + 8 });

            var getPdvElemWidth = $(".srPdv").width();
            $(".addPdvTotal").css({ "margin-left": "0", "width": Number(getPdvElemWidth) + 7 });
            $(".srServiceName").focus();
            event.stopImmediatePropagation();
        });
        $tableID.on('click', '.table-remove', function () {
            $(this).parents('tr').detach();
        });
        $tableID2.on('click', '.table-remove', function () {
            $(this).parents('tr').detach();
        });

        const jQuery = $;
        jQuery.fn.pop = [].pop;
        jQuery.fn.shift = [].shift;
        $BTN.on('click', () => {
            const $rows = $tableID.find('tr:not(:hidden)');
            const headers = [];
            const data = [];

            $($rows.shift()).find('th:not(:empty)').each(function () {
                headers.push($(this).text().toLowerCase());
            });

            $rows.each(function () {
                const $td = $(this).find('td');
                const h = {};

                headers.forEach((header, i) => {
                    h[header] = $td.eq(i).text();
                });
                data.push(h);
            });
            // Output the result
            $EXPORT.text(JSON.stringify(data));
        });

        $(".radioDivCh input").on("click", function () {
            var radioVal = $(this).val();

            if ($(".radioDiv").hasClass("srLng")) {
                $(".srServiceName").focus();
                if (radioVal === "hour") {
                    $(".srAddWorkTitle").text("Količina (h)");
                }
                if (radioVal === "day") {
                    $(".srAddWorkTitle").text("Količina (d)");
                }
                if (radioVal === "week") {
                    $(".srAddWorkTitle").text("Količina (n)");
                }
                if (radioVal === "month") {
                    $(".srAddWorkTitle").text("Količina (m)");
                }
            } else {
                $(".inServiceName").focus();
                $(".inAddWorkTitle").text("Work per " + radioVal);
            }
        });

        $("body").keyup("#table .inPriceWork", function () {
            var work = $(".inAddWork").last().text();
            var priceWork = $("#table .inPriceWork").last().text();
            var totalPrice = work * priceWork;
            $("#table .inTotalPrice").last().text(totalPrice.toFixed(2).replace(/\.0+$/, '.00'));
            var sum = 0;
            $("#table .inTotalPrice").each(function () {
                sum += Number($(this).text());
            });
            $("#table .totalNm").text(sum.toFixed(2).replace(/\.0+$/, '.00'));

            var isTdEmpty = $(".inPriceWork").last().text();
            if (isTdEmpty === '') {
                $(".table-add").hide();
            } else {
                $(".table-add").show();
            }
        });

        $("body").keyup("#table2 .srPriceWork", function () {
            var work = $(".srAddWork").last().text();
            var priceWork = $("#table2 .srPriceWork").last().text();
            var totalPrice = work * priceWork;
            $("#table2 .srTotalPrice").last().text(totalPrice.toFixed(2).replace(/\.0+$/, '.00'));

            var pos = $("#table2 .pos").last().width();
            var srServiceName = $(".srServiceName").last().width();
            var srPriceWork = $(".srPriceWork").last().width();
            var srTotalPrice = $(".srTotalPrice").last().width();
            var totalTd = Number(pos) + Number(srServiceName);

            var getElemWidth = $(".srAddWork").last().width();
            $(".addWorkTotal").css({ "margin-left": totalTd + 3, "width": Number(getElemWidth) + 2 });

            var sum2 = 0;
            $("#table2 .srAddWork").each(function () {
                sum2 += Number($(this).text());
            });
            $(".addWorkTotal").text(sum2.toFixed(2).replace(/\.0+$/, ''));

            var totalTd2 = Number(srPriceWork) + Number(srTotalPrice);
            var getOsElemWidth = $(".srTotalPricePdv").width();
            $(".addOsTotal").css({ "margin-left": Number(totalTd2) + 5, "width": Number(getOsElemWidth) + 8 });

            var sum3 = 0;
            $("#table2 .srTotalPricePdv").each(function () {
                sum3 += Number($(this).text());
            });

            $("#table2 .addOsTotal").text(sum3.toFixed(2).replace(/\.0+$/, ''));

            var getPdvElemWidth = $(".srPdv").width();
            $(".addPdvTotal").css({ "margin-left": "0", "width": Number(getPdvElemWidth) + 7 });

            var sum4 = 0;
            $("#table2 .srPdv").each(function () {
                sum4 += Number($(this).text());
            });
            $("#table2 .addPdvTotal").text(sum4.toFixed(2).replace(/\.0+$/, ''));

            var eurValue = $(".eurValue").val();
            var rsdConverted = totalPrice * eurValue;
            var rsdConverted2 = rsdConverted.toFixed(2).replace(/\.0+$/, '.00');
            $(".srTotalPricePdv").last().text(rsdConverted2);
            var srTotalPricePdv = $(".srTotalPricePdv").last().text();
            var calWithPdv = (srTotalPricePdv * 20) / 100;
            $("#table2 .srPdv").last().text(calWithPdv.toFixed(2).replace(/\.0+$/, '.00'));
            var withPdv = Number(srTotalPricePdv) + Number(calWithPdv);
            $("#table2 .srTotalPdv").last().text(withPdv.toFixed(2).replace(/\.0+$/, '.00'));

            var sum = 0;
            $("#table2 .srTotalPdv").each(function () {
                sum += Number($(this).text());
            });
            $("#table2 .srtotalNm").text(sum.toFixed(2).replace(/\.0+$/, ''));

            var isTdEmpty = $(".srPriceWork").last().text();
            if (isTdEmpty === '') {
                $(".table-add2").hide();
            } else {
                $(".table-add2").show();
            }

        });

        $("body").on("keydown", function (e) {
            if (e.ctrlKey && e.which === 32) {
                if ($(".radioDiv").hasClass("srLng")) {
                    $("#table2 .table-add2 i").click();
                    $(".srServiceName").focus();
                    e.stopImmediatePropagation();
                } else {
                    $("#table .table-add i").click();
                    $(".inServiceName").focus();
                    e.stopImmediatePropagation();
                }
            }
        });

        $(".todayDate").on("keydown", function () {
            var getDate = $(this).val();
            $(".modDate").text(getDate);
        });

        $(".qsCol input").focus(function () {
            $(".qsUpdateBtn").fadeIn();
        });
        $(".qsCol input").focusout(function () {
            $(".qsUpdateBtn").fadeOut();
        });
        $(".clientForm input").focus(function () {
            $(".clientUpdateBtn").fadeIn();
            $(".clientDeleteBtn").fadeIn();
        });
        $(".clientForm input").focusout(function () {
            $(".clientUpdateBtn").fadeOut();
            $(".clientDeleteBtn").fadeOut();
        });

    });

    function qsUpdate() {

        $(".qsUpdateBtn").fadeOut();
        var selecteQs = $("#qsBank").children("option:selected").attr("id");

        var newNameQ = $(".qsCol #formBasicText10").val();
        var newAddressQ = $(".qsCol #formBasicText11").val();
        var newCityQ = $(".qsCol #formBasicText12").val();
        var newStateQ = $(".qsCol #formBasicText13").val();
        var newEmailQ = $(".qsCol #formBasicText14").val();
        var newPhoneQ = $(".qsCol #formBasicText15").val();
        var newBankQ = $(".qsCol #formBasicText16").val();
        var newPibQ = $(".qsCol #formBasicText18").val();

        request
            .post('http://localhost:8080/api/quadrixsData/' + selecteQs)
            .set('Content-Type', 'application/json')
            .send({ qs_name: newNameQ, qs_address: newAddressQ, qs_cityzip: newCityQ, qs_state: newStateQ, qs_email: newEmailQ, qs_phone: newPhoneQ, qs_bank: newBankQ, qs_pib: newPibQ })
            .end(function (err, res) {

                if (res.status === 404) {
                    setTimeout(function () {
                        $(".qsSpinner").fadeIn();
                    }, 500);
                    setTimeout(function () {
                        $(".qsSpinner").fadeOut();
                    }, 1500);
                    setTimeout(function () {
                        $(".infoNotOkUpdateQs").fadeIn();
                    }, 2000);
                    setTimeout(function () {
                        $(".infoNotOkUpdateQs").fadeOut();
                    }, 5000);
                }

                if (res.status === 200 && res.body.message === "Contact Info updated") {
                    $(".btnSaveClient").fadeOut();
                    setTimeout(function () {
                        $(".qsSpinner").fadeIn();
                    }, 500);
                    setTimeout(function () {
                        $(".qsSpinner").fadeOut();
                    }, 1000);
                    setTimeout(function () {
                        $(".infoOkUpdateQs").fadeIn();
                    }, 1500);
                    setTimeout(function () {
                        $(".infoOkUpdateQs").fadeOut();
                    }, 5000);
                }
            });

    }

    function clientUpdate() {
        $(".clientUpdateBtn").fadeOut();
        $(".clientDeleteBtn").fadeOut();
        var selectedD = $("#selOption").children("option:selected").attr("id");

        var newName = $("#selOption").children("option:selected").val();
        var newAddress = $("#formBasicText2").val();
        var newCity = $("#formBasicText3").val();
        var newState = $("#formBasicText4").val();
        var newEmail = $("#formBasicText5").val();
        var newPhone = $("#formBasicText6").val();
        var newBank = $("#formBasicText8").val();
        var newTax = $("#formBasicText9").val();

        request
            .put('http://localhost:8080/api/clientsData/' + selectedD)
            .set('Content-Type', 'application/json')
            .send({ client_name: newName, client_address: newAddress, client_cityzip: newCity, client_state: newState, client_email: newEmail, clinet_phone: newPhone, client_bank: newBank, client_taxid: newTax })
            .end(function (err, res) {

                if (res.status === 404) {
                    setTimeout(function () {
                        $(".clientSpinner").fadeIn();
                    }, 500);
                    setTimeout(function () {
                        $(".clientSpinner").fadeOut();
                    }, 1500);
                    setTimeout(function () {
                        $(".infoNotOkUpdateClient").fadeIn();
                    }, 2000);
                    setTimeout(function () {
                        $(".infoNotOkUpdateClient").fadeOut();
                    }, 5000);
                }

                if (res.status === 200 && res.body.message === "Contact updated!") {
                    $(".btnSaveClient").fadeOut();
                    setTimeout(function () {
                        $(".clientSpinner").fadeIn();
                    }, 500);
                    setTimeout(function () {
                        $(".clientSpinner").fadeOut();
                    }, 1000);
                    setTimeout(function () {
                        $(".infoOkUpdateClient").fadeIn();
                    }, 1500);
                    setTimeout(function () {
                        $(".infoOkUpdateClient").fadeOut();
                    }, 5000);
                }
            });
    }

    function pdfPage() {
        if (!$(".inFild1").hasClass("hasVal")) {
            $(".customField").hide();
        }
        if (!$(".inFild2").hasClass("hasVal")) {
            $(".customField2").hide();
        }
        if (!$(".inFild3").hasClass("hasVal")) {
            $(".customField3").hide();
        }
        if (!$(".inFild4").hasClass("hasVal")) {
            $(".customField4").hide();
        }
        $(".radioDiv").hide();
        $("#table .iconCol").hide();
        $("#table2 .iconCol").hide();
        $(".btnForPdf").hide();
        $(".closeFieldInput").hide();
        $(".clientForm input").css("background", "#fff");

        var pos = $("#table2 .pos").last().width();
        var srServiceName = $(".srServiceName").last().width();
        var srPriceWork = $(".srPriceWork").last().width();
        var srTotalPrice = $(".srTotalPrice").last().width();
        var totalTd = Number(pos) + Number(srServiceName);

        var getElemWidth = $(".srAddWork").last().width();
        $(".addWorkTotal").css({ "margin-left": totalTd + 3, "width": Number(getElemWidth) + 1 });

        var totalTd2 = Number(srPriceWork) + Number(srTotalPrice);
        var getOsElemWidth = $(".srTotalPricePdv").width();
        $(".addOsTotal").css({ "margin-left": Number(totalTd2), "width": Number(getOsElemWidth) + 10 });

        var getPdvElemWidth = $(".srPdv").width();
        $(".addPdvTotal").css({ "margin-left": "0", "width": Number(getPdvElemWidth) + 9 });

        $(".srPriceWork").each(function () {
            var convertVal = $(this).text();
            $(this).text(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(convertVal));
        });
        $(".srTotalPrice").each(function () {
            var convertVal = $(this).text();
            $(this).text(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(convertVal));
        });
        $(".srTotalPricePdv").each(function () {
            var convertVal = $(this).text();
            $(this).text(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(convertVal));
        });
        $(".srPdv").each(function () {
            var convertVal = $(this).text();
            $(this).text(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(convertVal));
        });
        $(".srTotalPdv").each(function () {
            var convertVal = $(this).text();
            $(this).text(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(convertVal));
        });
        var convertValaddOsTotal = $(".addOsTotal").text();
        $(".addOsTotal").text(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(convertValaddOsTotal));
        var convertValaddPdvTotal = $(".addPdvTotal").text();
        $(".addPdvTotal").text(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(convertValaddPdvTotal));
        var convertValtotalNm = $(".srtotalNm").text();
        $(".srtotalNm").text(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(convertValtotalNm));

        $(".inTotalPrice").each(function () {
            var convertValTp = $(this).text();
            $(this).text(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(convertValTp));
        });
        var convertValtotalNmin = $(".totalNm").text();
        $(".totalNm").text(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(convertValtotalNmin));

        $(".qsUpdateBtn").fadeOut();
        $(".clientUpdateBtn").fadeOut();
        $(".clientDeleteBtn").fadeOut();

        var getFormBasicNo = $(".invoiceNumField").val();
        var splitedInvNum = String(getFormBasicNo).split("/")[0];
        var idNumInvoice = "6156b599a894192ffc02cc86";

        request
            .patch('http://localhost:8080/api/invoiceNum/' + idNumInvoice)
            .set('Content-Type', 'application/json')
            .send({ last_invoce_num: splitedInvNum })
            .end(function (err, res) {
                console.log(res);
            });
    }

    function clientDelete() {
        var selectedD = $("#selOption").children("option:selected").attr("id");

        request
            .delete('http://localhost:8080/api/clientsData/' + selectedD)
            .set('Content-Type', 'application/json')
            .end(function (err, res) {

                if (res.status === 404) {
                    $(".clientDeleteBtn").fadeOut();
                    $(".clientUpdateBtn").fadeOut();
                    setTimeout(function () {
                        $(".clientSpinner").fadeIn();
                    }, 500);
                    setTimeout(function () {
                        $(".clientSpinner").fadeOut();
                    }, 1000);
                    setTimeout(function () {
                        $(".infoNotOkDeleteClient").fadeIn();
                    }, 1500);
                    setTimeout(function () {
                        $(".infoNotOkDeleteClient").fadeOut();
                    }, 5000);
                }

                if (res.status === 200 && res.body.message === "Client deleted") {
                    $(".clientDeleteBtn").fadeOut();
                    $(".clientUpdateBtn").fadeOut();
                    setTimeout(function () {
                        $(".clientSpinner").fadeIn();
                    }, 500);
                    setTimeout(function () {
                        $(".clientSpinner").fadeOut();
                    }, 1000);
                    setTimeout(function () {
                        $(".infoOkDeleteClient").fadeIn();
                    }, 1500);
                    setTimeout(function () {
                        $(".infoOkDeleteClient").fadeOut();
                    }, 5000);

                    $("#" + selectedD).remove();
                    $(".clientForm input").val("");
                    $(".clientForm input").val("").css("background", "#e4e4e4");;
                    $(".formBasicText1a").hide();
                    $(".formBasicText1").show();
                    $("#selOption").val($("#selOption option:first").val());
                    // $("#formBasicText2").val("").css("background", "#e4e4e4");
                    // $("#formBasicText3").val("").css("background", "#e4e4e4");
                    // $("#formBasicText4").val("").css("background", "#e4e4e4");
                    // $("#formBasicText5").val("").css("background", "#e4e4e4");
                    // $("#formBasicText6").val("").css("background", "#e4e4e4");
                    // $("#formBasicText8").val("").css("background", "#e4e4e4");
                    // $("#formBasicText9").val("").css("background", "#e4e4e4");
                }
            });
    }
    let pdfName = '';
    $("body").on("change", "#selOption", function () {
        pdfName = $(this).children("option:selected").val();
    });

    return (
        <>
            <div className="page-container hidden-on-narrow">
                <PDFExport ref={pdfExportComponent} fileName={{ pdfName } + '.pdf'}>
                    <div className="pdf-page size-a4">
                        <div className="inner-page">
                            <Container className="boxShadowContainer">
                                <div className="headerDiv">
                                    <img src={logo} alt="logo" />
                                </div>
                                <Form className="invoiceForm">
                                    <Row className="">
                                        <Col md={5} className="qsCol">
                                            <Form.Group className="mb-0" controlId="formBasicText10">
                                                <Form.Control className="" size="sm" type="text" style={{ fontWeight: "bold" }} />
                                            </Form.Group>
                                            <hr />
                                            <Form.Group as={Row} className="mb-0" controlId="formBasicText11">
                                                <Col>
                                                    <Form.Control className="inAddress active" size="sm" type="text" />
                                                    {/* <Form.Control className="srAddress dp-none" size="sm" type="text" /> */}
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-0" controlId="formBasicText12">
                                                <Col>
                                                    <Form.Control className="inCityZip active" size="sm" type="text" />
                                                    {/* <Form.Control className="srCityZip dp-none" size="sm" type="text" /> */}
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-0" controlId="formBasicText13">
                                                <Col>
                                                    <Form.Control className="inState" size="sm" type="text" />
                                                    {/* <Form.Control className="srState dp-none" size="sm" type="text" /> */}
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-0" controlId="formBasicText14">
                                                <Col>
                                                    <Form.Control className="" size="sm" type="text" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-0" controlId="formBasicText15">
                                                <Col>
                                                    <Form.Control className="" size="sm" type="text" />
                                                </Col>
                                            </Form.Group>
                                            <Row>
                                                <Col className="qsBankInput" md={7.5}>
                                                    <Form.Group className="mb-0" controlId="formBasicText16">
                                                        <Form.Control className="" size="sm" type="text" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className="qsBank" md={2.5}>
                                                    <Form.Group className="mb-0" controlId="formBasicText17">
                                                        <select id="qsBank" className="form-control form-control-sm">
                                                            <option id="6156b917a894192ffc02cc96">EUR</option>
                                                            <option id="6156bb54a894192ffc02cc9b">USD</option>
                                                            <option id="6156bcbda894192ffc02cca6">RSD</option>
                                                        </select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                </Col>
                                            </Row>
                                            <Form.Group as={Row} className="mb-0" controlId="formBasicText18">
                                                <Col>
                                                    <Form.Control className=" pibNum" size="sm" type="text" />
                                                </Col>
                                            </Form.Group>
                                            <hr />
                                            <span className="qsUpdateBtn dp-none" onClick={qsUpdate} >Update data</span>
                                            <span className="infoOkUpdateQs dp-none"><i className="bi bi-check"></i>The data has been updated!</span>
                                            <span className="infoNotOkUpdateQs dp-none">The data has not been updated!</span>
                                            <div className="spinner-border qsSpinner dp-none" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </Col>
                                        <Col md={2} className="midCol">
                                            <span className="inTxt">Address</span><span className="srTxt dp-none">Adresa</span>
                                            <span className="inTxt">City, Zip</span><span className="srTxt dp-none">Grad, Poš.br</span>
                                            <span className="inTxt">State</span><span className="srTxt dp-none">Država</span>
                                            <span className="inTxt">Email</span><span className="srTxt dp-none">Email</span>
                                            <span className="inTxt">Phone</span><span className="srTxt dp-none">Telefon</span>
                                            <span className="mb-0 inTxt">Bank Account</span><span className="mb-0 srTxt dp-none">Bankovni Račun</span>
                                            <Form.Group className="mt-1  mb-0" controlId="formBasicText7">
                                                <select id="selTax" className="form-control form-control-sm">
                                                    <option>VAT</option>
                                                    <option>TIN</option>
                                                    <option>PIB</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={5} className="clientForm">
                                            <Form.Group id="formBasicText1" className="mb-0 formBasicText1" controlId="formBasicText1">
                                                <select id="selOption" className="form-control form-control-sm clientSel text-right" style={{ fontWeight: "bold" }}>
                                                    <option>Select Client</option>
                                                    <option>New Client</option>
                                                </select>
                                            </Form.Group>
                                            <Form.Group id="formBasicText1a" className="mb-0 dp-none formBasicText1a">
                                                <Form.Control className="text-right" size="sm" type="text" placeholder="Enter New Client" style={{ fontWeight: "bold" }} />
                                            </Form.Group>
                                            <hr />
                                            <Form.Group className="mb-0" controlId="formBasicText2">
                                                <Form.Control className="text-right" size="sm" type="text" placeholder="Client's Address " />
                                            </Form.Group>
                                            <Form.Group className="mb-0" controlId="formBasicText3">
                                                <Form.Control className="text-right" size="sm" type="text" placeholder="City, Zip " />
                                            </Form.Group>
                                            <Form.Group className="mb-0" controlId="formBasicText4">
                                                <Form.Control className="text-right" size="sm" type="text" placeholder="State " />
                                            </Form.Group>
                                            <Form.Group className="mb-0" controlId="formBasicText5">
                                                <Form.Control className="text-right" size="sm" type="email" placeholder="Client's Email " />
                                            </Form.Group>
                                            <Form.Group className="mb-0" controlId="formBasicText6">
                                                <Form.Control className="text-right" size="sm" type="email" placeholder="Phone Number " />
                                            </Form.Group>
                                            <Form.Group className="mb-0" controlId="formBasicText8">
                                                <Form.Control className="text-right" size="sm" type="email" placeholder="Enter Value " />
                                            </Form.Group>
                                            <Form.Group className="mb-0" controlId="formBasicText9">
                                                <Form.Control className="text-right" size="sm" type="text" placeholder="Enter Value " />
                                            </Form.Group>
                                            <hr />
                                            <span className="clientUpdateBtn dp-none" onClick={clientUpdate} >Update data</span>
                                            <span className="clientDeleteBtn dp-none" onClick={clientDelete} >Delete client</span>
                                            <span className="infoOkDeleteClient dp-none"><i className="bi bi-check"></i>The client has been deleted!</span>
                                            <span className="infoNotOkDeleteClient dp-none">The client has not been deleted!</span>
                                            <span className="infoOkUpdateClient dp-none"><i className="bi bi-check"></i>The client has been updated!</span>
                                            <span className="infoNotOkUpdateClient dp-none">The client has not been updated!</span>
                                            <div className="spinner-border clientSpinner dp-none" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mx-auto mt-3 invoiceNumCol">
                                            <Form.Group className="mb-3" controlId="formBasicNo">
                                                <Form.Label className="invoiceTitle" column md={6}>Invoice No.</Form.Label>
                                                <Form.Control size="md" className="text-center invoiceNumField" type="text" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="customFields">
                                        <Col md={12} className="projTitleCol">
                                            <Form.Group className="mb-0" controlId="formBasicText19">
                                                <Form.Label className="inTxt">Project Name: </Form.Label><Form.Label className="srTxt dp-none">Naziv Usluge: </Form.Label>
                                                <Form.Control className="bb-1" size="sm" type="text" placeholder="Enter Value" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} className="dp-none srDatProm">
                                            <Form.Group className="mb-0" controlId="formBasicText20">
                                                <Form.Label className="srTxt dp-none">Datum prometa: </Form.Label>
                                                <Form.Control className="todayBill bb-1" size="sm" type="text" placeholder="Enter Value" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-0" controlId="formBasicText21">
                                                <Form.Label className="inTxt">Invoice Date: </Form.Label><Form.Label className="srTxt dp-none">Datum Računa: </Form.Label>
                                                <Form.Control className="todayDate bb-1" size="sm" type="text" placeholder="Enter Value" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-0" controlId="formBasicText22">
                                                <Form.Label className="inTxt">Date of Payment: </Form.Label><Form.Label className="srTxt dp-none">Rok Plaćanja: </Form.Label>
                                                <Form.Control className="bb-1" size="sm" type="text" placeholder="Enter Value" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-0" controlId="formBasicText23">
                                                <Form.Label className="inTxt">Invoice Place: </Form.Label><Form.Label className="srTxt dp-none">Mesto Računa: </Form.Label>
                                                <Form.Control className="bb-1" size="sm" type="text" placeholder="Enter Value" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <div className="customField">
                                                <Form.Control className="mt-1" size="sm" type="text" placeholder="Field Name:" />
                                                <div className="addInputField">
                                                    <i className="bi bi-plus-square-dotted"></i> <span>Add field</span>
                                                </div>
                                                <div className="newInputField dp-none">
                                                    <div className="inlineDiv">
                                                        <Form.Control className="bb-1 inFild1" size="sm" type="text" placeholder="Enter Value" />
                                                        <i className="bi bi-x-circle dp-none closeField"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="customField2 dp-none">
                                                <Form.Control className="mt-1" size="sm" type="text" placeholder="Field Name:" />
                                                <div className="addInputField">
                                                    <i className="bi bi-plus-square-dotted"></i> <span>Add field</span>
                                                </div>
                                                <div className="newInputField dp-none">
                                                    <div className="inlineDiv">
                                                        <Form.Control className="bb-1 inFild2" size="sm" type="text" placeholder="Enter Value" />
                                                        <i className="bi bi-x-circle dp-none closeField"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="customField3 dp-none">
                                                <Form.Control className="mt-1" size="sm" type="text" placeholder="Field Name:" />
                                                <div className="addInputField">
                                                    <i className="bi bi-plus-square-dotted"></i> <span>Add field</span>
                                                </div>
                                                <div className="newInputField dp-none">
                                                    <div className="inlineDiv">
                                                        <Form.Control className="bb-1 inFild3" size="sm" type="text" placeholder="Enter Value" />
                                                        <i className="bi bi-x-circle dp-none closeField"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        {/* <Col md={3}>
                                            <div className="customField4 dp-none">
                                                <Form.Control className="mt-1" size="sm" type="text" placeholder="Field Name:" />
                                                <div className="addInputField">
                                                    <i className="bi bi-plus-square-dotted"></i> <span>Add field</span>
                                                </div>
                                                <div className="newInputField dp-none">
                                                    <div className="inlineDiv">
                                                        <Form.Control className="bb-1 inFild4" size="sm" type="text" placeholder="Enter Value" />
                                                        <i className="bi bi-x-circle dp-none closeField"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col> */}
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div className="radioDiv inLng">
                                                <span className="typeCal inTxt">The type of calculation: </span><span className="typeCal srTxt dp-none">Tip obračuna: </span>
                                                <div className="radioDivCh">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="hour" />
                                                        <label className="form-check-label inTxt" htmlFor="inlineRadio1">h</label>
                                                        <label className="form-check-label srTxt dp-none" htmlFor="inlineRadio1">h</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="day" />
                                                        <label className="form-check-label inTxt" htmlFor="inlineRadio2">d</label>
                                                        <label className="form-check-label srTxt dp-none" htmlFor="inlineRadio2">d</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="week" />
                                                        <label className="form-check-label inTxt" htmlFor="inlineRadio3">w</label>
                                                        <label className="form-check-label srTxt dp-none" htmlFor="inlineRadio3">n</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="month" />
                                                        <label className="form-check-label inTxt" htmlFor="inlineRadio4">m</label>
                                                        <label className="form-check-label srTxt dp-none" htmlFor="inlineRadio4">m</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="table" className="table-editable">
                                                <table className="table table-bordered table-responsive-md table-striped text-center">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">Pos</th>
                                                            <th className="text-center">Name of service </th>
                                                            <th className="text-center inAddWorkTitle">Work </th>
                                                            <th className="text-center">Work Price</th>
                                                            <th className="text-center">Total</th>
                                                            <th className="text-center iconCol">
                                                                <span className="table-add dp-none">
                                                                    <a href="#!" className="text-success">
                                                                        <i className="bi bi-plus-square"></i>
                                                                        <span className="tooltipAdd">shortcut SHIFT + SPACE</span>
                                                                    </a>
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pt-1-half pos" contentEditable="true" suppressContentEditableWarning={true}>1</td>
                                                            <td className="pt-3-half inServiceName" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="pt-3-half inAddWork" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="pt-2-half inPriceWork" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="pt-2-half inTotalPrice" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="iconCol">
                                                                <span className="table-remove">
                                                                    {/* <button type="button" className="btn btn-danger btn-rounded btn-sm my-0"> */}
                                                                    <i className="bi bi-x-circle"></i>
                                                                    {/* </button> */}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="totalDiv">
                                                    <span className="totalTxt">Total for payment: </span>
                                                    <span className="totalNm"></span><span className="euVal">€&nbsp; </span><span className="dolVal dp-none">$&nbsp; </span>
                                                </div>
                                            </div>

                                            <div id="table2" className="table-editable dp-none">
                                                <table className="table table-bordered table-responsive-md table-striped text-center">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">Br.</th>
                                                            <th className="text-center">Naziv usluge</th>
                                                            <th className="text-center srAddWorkTitle">Količina</th>
                                                            <th className="text-center">Cena usluge (EUR)</th>
                                                            <th className="text-center">Ukupno (EUR)</th>
                                                            <th className="text-center">Osnovica</th>
                                                            <th className="text-center">PDV</th>
                                                            <th className="text-center">Ukupno</th>
                                                            <th className="text-center iconCol">
                                                                <span className="table-add2 dp-none">
                                                                    <a href="#!" className="text-success">
                                                                        <i className="bi bi-plus-square"></i>
                                                                        <span className="tooltipAdd">shortcut SHIFT + SPACE</span>
                                                                    </a>
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pt-1-half pos" contentEditable="true" suppressContentEditableWarning={true}>1</td>
                                                            <td className="pt-3-half srServiceName" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="pt-3-half srAddWork" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="pt-2-half srPriceWork" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="pt-2-half srTotalPrice" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="pt-2-half srTotalPricePdv" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="pt-2-half srPdv" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="pt-2-half srTotalPdv" contentEditable="true" suppressContentEditableWarning={true}></td>
                                                            <td className="iconCol">
                                                                <span className="table-remove">
                                                                    {/* <button type="button" className="btn btn-danger btn-rounded btn-sm my-0"> */}
                                                                    <i className="bi bi-x-circle"></i>
                                                                    {/* </button> */}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <Row className="totalDiv">
                                                    <Col md={12}>
                                                        <span className="addWorkTotal"></span>
                                                        <span className="addOsTotal"></span>
                                                        <span className="addPdvTotal"></span>
                                                    </Col>
                                                </Row>
                                                <Row className="totalDiv2">
                                                    <Col md={3}>
                                                        <span className="totalTxt">Ukupno za uplatu: </span>
                                                    </Col>
                                                    <Col md={6} className="mb-0" controlId="sumTxt">
                                                        <Form.Group className="mb-0" controlId="formBasicText88">
                                                            <Form.Control className="text-center sumTxt" size="sm" as="textarea" rows={1} placeholder="Unesite iznos slovima" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <span className="srTotal">&nbsp; RSD</span>
                                                        <span className="srtotalNm"></span>
                                                    </Col>
                                                </Row>
                                                <Row className="exchangeEu">
                                                    <Col className="eurCol">
                                                        <span>*Kurs evra po narodnoj banci Srbije, za datum <span className="modDate">{today}</span>: 1€&nbsp; = <input className="eurValue"></input> RSD</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row className="signaturesRow euRow">
                                    <Col>
                                        <p>Please transfer the aboved mentioned amount to the following bank: </p>
                                        <p>Bank name: RAIFFEISEN BANK AD BEOGRAD, RS</p>
                                        <p>SWIFT/BIC: </p>
                                        <p>Raiffeisen Bank International AG, Deutsche Bank AG, Germany <strong>DEUTDEFF</strong></p>
                                        <p>59: Beneficiary customer, IBAN: <strong>RS35265100000021888392</strong></p><br /><br />
                                        <p>Signatures: </p>
                                        <p>Quadrix Soft</p>
                                        <span className="signPlace"></span>
                                    </Col>
                                </Row>
                                <Row className="signaturesRow usdRow dp-none">
                                    <Col>
                                        <p>Please transfer the aboved mentioned amount to the following bank: </p>
                                        <p>Bank name: RAIFFEISEN BANK AD BEOGRAD, RS</p>
                                        <p>SWIFT/BIC: </p>
                                        <p>Raiffeisen Bank International AG, Vienna <strong>RZBAATWW</strong> </p>
                                        <p>59: Beneficiary customer, IBAN: <strong>RS35265100000021888392</strong></p><br /><br />
                                        <p>Signatures: </p>
                                        <p>Quadrix Soft</p>
                                        <span className="signPlace"></span>
                                    </Col>
                                </Row>
                                <Row className="signaturesRow rsdRow dp-none">
                                    <Col md={6}>
                                        <p>Račun primio: </p>
                                        <span className="signPlace"></span>
                                    </Col>
                                    <Col md={6} className="fakPlace">
                                        <p>Fakturisao: </p>
                                        <span className="signPlace2"></span>
                                    </Col>
                                </Row>
                            </Container >
                        </div>
                    </div>
                </PDFExport>
                <button className="btnSaveClient dp-none">SAVE NEW CLIENT</button>
                <button className="btnForPdf" onClick={pdfPage}>REMOVE CUSTOM FIELDS</button>
                <button className="btnForPdfExport" primary="true" onClick={handleExportWithComponent}>EXPORT TO PDF</button>
                <span className="infoOk dp-none"><i className="bi bi-check"></i> New client created!</span>
                <span className="infoNotOk dp-none">New client is not created!</span>
                <div className="spinner-border newClientSpinner dp-none" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
}

export default InvoicePage;